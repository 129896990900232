
import React, { useState, useEffect,useCallback } from 'react';
import axios from 'axios';

const RevenueTable = ({ selectedPlant }) => {
  const [revenueData, setRevenueData] = useState(null);
  const [data, setData] = useState({
    "Solar Generation": { kWh: "", "Unit Price": 0.13, Amount: 0 },
    "Exported Generation": { kWh: "", "Unit Price": "", Amount: 0 },
    "Consumed Generation": { kWh: "", "Unit Price": 0.13, Amount: 0 },
    "Administrative Fees": { Amount: "" },
    "Total Revenue": { Amount: 0 },
    "Variance in Revenue": { Amount: 0 },
    "Number of REC (Cumulative)": 0,
    "REC Sold": 0,
    "Balance REC (Cumulative)": 0,
  });

  const [monthOptions, setMonthOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  // const [month, setMonth] = useState('');
 

  const [isModalOpen, setIsModalOpen] = useState(false);

  const formatNumberWithCommas = (number) => {
    return number != null && !isNaN(number)
      ? parseFloat(number).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : "0.00";
  };

  const parseNumber = (value) => {
    const stringValue = value != null ? String(value).trim() : "";
    if (stringValue === "") return 0;
    const parsedValue = parseFloat(stringValue.replace(/,/g, ""));
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const calculateAmount = (kWh, unitPrice) => {
    const kWhValue = parseNumber(kWh);
    const unitPriceValue = parseNumber(unitPrice);
    return kWhValue * unitPriceValue;
  };

  // const calculateConsumedKWh = (solarKWh, exportedKWh) => {
  //   const solar = parseNumber(solarKWh);
  //   const exported = parseNumber(exportedKWh);
  //   return Math.max(0, solar - exported);
  // };
  const monthMapping = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec'
  };

  
  useEffect(() => {
    const solarKWh = data["Solar Generation"].kWh || 0; 
    const exportedKWh = data["Exported Generation"].kWh || 0;
    const consumedKWh = solarKWh - exportedKWh;
    const solarUnitPrice = 0.13; 
    const exportedUnitPrice = data["Exported Generation"]["Unit Price"] || 0;
    const consumedUnitPrice = data["Consumed Generation"]["Unit Price"] || 0;
  
    const adminFeesAmount = parseNumber(data["Administrative Fees"].Amount);
    const solarAmount = calculateAmount(solarKWh, solarUnitPrice);
    const exportedAmount = calculateAmount(exportedKWh, exportedUnitPrice);
    const consumedAmount = calculateAmount(consumedKWh, consumedUnitPrice);

    const totalRevenue = exportedAmount + consumedAmount + adminFeesAmount;
    const varianceInRevenue = totalRevenue - solarAmount;
  
    setData((prevData) => ({
      ...prevData,
      "Solar Generation": { ...prevData["Solar Generation"], Amount: solarAmount, "Unit Price": solarUnitPrice },
      "Exported Generation": { ...prevData["Exported Generation"], Amount: exportedAmount },
      "Consumed Generation": { ...prevData["Consumed Generation"], kWh: consumedKWh, Amount: consumedAmount },
      "Total Revenue": { Amount: totalRevenue },
      "Variance in Revenue": { Amount: varianceInRevenue },
    }));
  }, [
    data["Solar Generation"].kWh,
    data["Exported Generation"].kWh,
    data["Exported Generation"]["Unit Price"],
    data["Consumed Generation"]["Unit Price"],
    data["Administrative Fees"].Amount,
  ]);
  
  const handleInputChange = (e, field, subField) => {
    const value = e.target.value;
    setData((prevData) => ({
      ...prevData,
      [field]: {
        ...prevData[field],
        [subField]: value,
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`/fmsolaryield`, {
          plant: selectedPlant,
          year: 2025,
        });
  
        if (response.data && Array.isArray(response.data)) {
          const lastThreeMonths = response.data.slice(-2).map((item) => {
            const [year, month] = item.StatisticalPeriod.split('-');
            const monthShort = monthMapping[month]; 
            return {
              month: `${monthShort}-${year.slice(-2)}`, 
              yield: parseFloat(item.TotalInverterYield) || 0,
            };
          });
  
          setMonthOptions(lastThreeMonths);
        } else {
          console.error("Unexpected data format", response.data);
        }
      } catch (error) {
        console.error("Error fetching solar yield data:", error);
      }
    };
  
    fetchData();
  }, [selectedPlant]);
  
  const sendDataToBackend = async () => {
    if (!selectedMonth) {
      alert('Please select a month');
      return; 
    }
  
    try {
     
      const roundToTwoDecimalPlaces = (value) => {
        if (typeof value === 'number') {
          return parseFloat(value.toFixed(2)); 
        }
        return value; 
      };
  
      
      const roundedData = Object.fromEntries(
        Object.entries(data).map(([key, value]) => {
          if (typeof value === 'object') {
            
            return [key, Object.fromEntries(
              Object.entries(value).map(([subKey, subValue]) => [subKey, roundToTwoDecimalPlaces(subValue)])
            )];
          }
          return [key, roundToTwoDecimalPlaces(value)];
        })
      );
  
     
      const payload = {
        month: selectedMonth,
        data: roundedData,
      };
  
      //console.log('Sending data:', payload); 
  
      const response = await axios.post('/fminsert', payload);
  
      if (response.status === 200) {
        alert('Data saved successfully');
        setData({
          "Solar Generation": { kWh: "", "Unit Price": "", Amount: 0 },
          "Exported Generation": { kWh: "", "Unit Price": "", Amount: 0 },
          "Consumed Generation": { kWh: "", "Unit Price": "", Amount: 0 },
          "Administrative Fees": { Amount: "" },
          "Total Revenue": { Amount: 0 },
          "Variance in Revenue": { Amount: 0 },
          "Number of REC (Cumulative)": 0,
          "REC Sold": 0,
          "Balance REC (Cumulative)": 0,
        });
        setSelectedMonth(''); 
      } else {
        alert('Error saving data');
      }
    } catch (error) {
      console.error('Error sending data to backend:', error);
      alert('Error saving data');
    }
  };
  

  const handleMonthChange = (e) => {
    const selected = e.target.value;
    setSelectedMonth(selected);

    const selectedData = monthOptions.find((option) => option.month === selected);
    if (selectedData) {
      setData((prevData) => ({
        ...prevData,
        "Solar Generation": { ...prevData["Solar Generation"], kWh: selectedData.yield },
      }));
    }
  };

  const fetchRevenueData = useCallback(async () => {
    try {
      const response = await axios.post('/fmrequest', {
        plant: selectedPlant,
      });

      if (Array.isArray(response.data) && response.data.length > 0) {
        const revenueData = response.data[0].Revenue;
        console.log("Revenue data fetched:", revenueData); 

        if (revenueData) {
          const parsedRevenueData = typeof revenueData === 'string' ? JSON.parse(revenueData) : revenueData;
          setRevenueData(parsedRevenueData);
        }
      }
    } catch (error) {
      console.error("There was an error fetching the data!", error);
      setRevenueData(null);
    }
  }, [selectedPlant]);

  useEffect(() => {
    fetchRevenueData();
  }, [fetchRevenueData]);

  const renderMetricData = (metric, month) => {
    if (revenueData && revenueData[month] && revenueData[month][metric]) {
      const data = revenueData[month][metric];
      console.log(`Data for ${metric} in ${month}:`, data);
  
      if (metric === "Number of REC (Cumulative)" || metric === "Balance REC (Cumulative)") {
        return { kWh: 'N/A', unitPrice: 'N/A', amount: data !== undefined ? String(data) : 'N/A' };
      } else if (metric === "REC Sold") {
        return { kWh: 'N/A', unitPrice: 'N/A', amount: data !== null ? String(data) : '-' };
      } else if (data && typeof data === 'object') {
        const kWh = data.kWh !== undefined ? data.kWh : 'N/A';
        const unitPrice = data["Unit Price"] !== undefined ? data["Unit Price"] : 'N/A';
        const amount = data.Amount !== undefined ? data.Amount : 'N/A';
  
        return {
          kWh: String(kWh), 
          unitPrice: String(unitPrice), 
          amount: String(amount), 
        };
      }
    }
    return { kWh: 'N/A', unitPrice: 'N/A', amount: 'N/A' };
  };

  const getMonths = () => {
    if (revenueData) {
      return Object.keys(revenueData);
    }
    return [];
  };

  const getDisplayPlantName = (plant) => {
  
    switch (plant) {
        case 'Bodyknits':
            return 'Bodynits';
        case 'Sweelee':
            return 'Swee Lee';
        default:
            return plant;
    } 
};
const displayPlantName = getDisplayPlantName(selectedPlant);
const billingCycles = {
  "Swee Lee": "Billing cycle from 10th of current month to 9th of following month",
  "Bodynits": "Billing cycle from 15th of current month to 14th of following month",
 "NicoSteel": "Billing cycle from 25th of current month to 24th of following month",
};

// Default to "Swee Lee" if no matching plant name is found
const billingCycleText = billingCycles[displayPlantName] || "Billing cycle details unavailable";


  return (
    <div>
    <h3 style={{ color: "red" }}>TREK RENEWABLE ENERGY SOLUTIONS PTE. LTD.</h3>
    <h4> {displayPlantName} Project</h4>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '20px', border: '2px solid #bbb', marginTop: '25px' }}>
      <div style={{ width: '90%', overflowX: 'auto' }}>
    
      <div>
      <h6>{`${displayPlantName} Project (${billingCycleText})`}</h6>
        {/* <h6>Swee Lee Project (Billing cycle from 10th of current month to 9th of following month)</h6> */}
      <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px',}}>
        <thead>
          <tr>
           
            <th rowSpan={2} style={{ padding: '12px',position: 'sticky',left: 0, backgroundColor: '#fff',zIndex:2}}>

          </th>
        {getMonths().map((month) => (
              <th
                key={month}
                colSpan="3"
                style={{padding: '12px', border: '2px solid #bbb',backgroundColor: '#FFFF8F'
                  ,textAlign: 'center',fontWeight: 'bold',fontSize: '14px',

                }}
              >
                {month}
              </th>
            ))}
          </tr>
          <tr>
          
            {getMonths().map((month) => (
              <React.Fragment key={month}>
                <th
                  style={{
                    padding: '12px', border: '2px solid #bbb',backgroundColor: 'lightblue',textAlign: 'center',fontWeight: 'bold',fontSize: '14px',
                     width:"25%",
                  }}
                >
                  kWh
                </th>
                <th
                  style={{
                    padding: '12px', border: '2px solid #bbb',backgroundColor: 'lightblue',textAlign: 'center',fontWeight: 'bold',fontSize: '14px',
                    width:"25%"
                  }}
                >
                  Unit Price (S$)
                </th>
                <th
                  style={{
                    padding: '12px', border: '2px solid #bbb',backgroundColor: 'lightblue',textAlign: 'center',
                    fontWeight: 'bold',fontSize: '14px',width:"25%"

                  }}
                >
                  Amount (S$)
                </th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
        {['Solar Generation'].map((metric) => (
    <tr key={metric}>
      <td style={{ position: metric === 'Solar Generation' ? 'sticky' : 'static', left: 0, backgroundColor: '#40826D', color: 'white', fontWeight: 'bold' }}>
        {metric}
      </td> 
            {getMonths().map((month) => {
              const { kWh, unitPrice, amount } = renderMetricData('Solar Generation', month);
              return (
                <React.Fragment key={month}>
                  <td style={{ fontWeight:"bold",padding: '8px', border: '2px solid #bbb',textAlign:'right'}}>{parseFloat(kWh).toLocaleString()}</td>
                  <td style={{fontWeight:"bold",padding: '8px', border: '2px solid #bbb',textAlign:"right"}}>{unitPrice}</td>
                  <td style={{ fontWeight:"bold",padding: '8px', border: '2px solid #bbb',textAlign:"right" }}>{parseFloat(amount).toLocaleString()}</td>
                </React.Fragment>
              );
            })}
          </tr>
             ))}
        
          {[ 'Exported Generation'].map((metric) => (
    <tr key={metric}>
      <td style={{ position: metric === 'Exported Generation' ? 'sticky' : 'static', left: 0, backgroundColor: '#40826D', color: 'white', fontWeight: 'bold' }}>
        {metric}
      </td> 
            
            {getMonths().map((month) => {
              const { kWh, unitPrice, amount } = renderMetricData('Exported Generation', month);
              return (
                <React.Fragment key={month}>
                  <td style={{ fontWeight:"bold",padding: '8px', border: '2px solid #bbb',textAlign:"right" }}>{parseFloat(kWh).toLocaleString()}</td>
                  <td style={{ fontWeight:"bold",padding: '8px', border: '2px solid #bbb',textAlign:"right"}}>{unitPrice}</td>
                  <td style={{ fontWeight:"bold",padding: '8px', border: '2px solid #bbb',textAlign:"right"}}>{parseFloat(amount).toLocaleString()}</td>
                </React.Fragment>
              );
            })}
          </tr>
          ))}
           {[ 'Consumed Generation'].map((metric) => (
    <tr key={metric}>
      <td style={{ position: metric === 'Consumed Generation' ? 'sticky' : 'static', left: 0, backgroundColor: '#40826D', color: 'white', fontWeight: 'bold' }}>
        {metric}
      </td> 
            {getMonths().map((month) => {
              const { kWh, unitPrice, amount } = renderMetricData('Consumed Generation', month);
              return (
                <React.Fragment key={month}>
                  <td style={{fontWeight:"bold",padding: '8px', border: '2px solid #bbb',textAlign:"right"}}>{parseFloat(kWh).toLocaleString()}</td>
                  <td style={{fontWeight:"bold",padding: '8px', border: '2px solid #bbb',textAlign:"right"}}>{unitPrice}</td>
                  <td style={{fontWeight:"bold",padding: '8px', border: '2px solid #bbb',textAlign:"right"}}>{parseFloat(amount).toLocaleString()}</td>
                </React.Fragment>
              );
            })}
          </tr>
           ))}
           {[ 'Administrative Fees'].map((metric) => (
    <tr key={metric}>
      <td style={{ position: metric === 'Administrative Fees' ? 'sticky' : 'static', left: 0, backgroundColor: '#40826D', color: 'white', fontWeight: 'bold' }}>
        {metric}
      </td> 
            {getMonths().map((month) => (
              <React.Fragment key={month}>
                <td colSpan="3" style={{ padding: '8px', border: '2px solid #bbb',textAlign:"right",fontWeight:"bold" }}>
                  
                  {(() => {
        const amount = renderMetricData('Administrative Fees', month).amount;
        return amount < 0 ? `(${Math.abs(amount)})` : amount || '0';
      })()}
                </td>
              </React.Fragment>
            ))}
          </tr>
           ))}
         
         {[ 'Total Revenue'].map((metric) => (
    <tr key={metric}>
      <td style={{ position: metric === 'Total Revenue' ? 'sticky' : 'static', left: 0, backgroundColor: '#40826D', color: 'white', fontWeight: 'bold' }}>
        {metric}
      </td> 

 {getMonths().map((month) => {
  const amount = renderMetricData('Total Revenue', month).amount;
  return (
    <React.Fragment key={month}>
      <td colSpan="3" style={{ padding: '8px', border: '2px solid #bbb',textAlign:"right",fontWeight:"bold",color:"green" }}>
        {amount ? parseFloat(amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'}
      </td>
    </React.Fragment>
  );
})}
</tr>
))}
 {[ 'Variance in Revenue'].map((metric) => (
    <tr key={metric}>
      <td style={{ position: metric === 'Variance in Revenue' ? 'sticky' : 'static', left: 0, backgroundColor: '#40826D', color: 'white', fontWeight: 'bold' }}>
        {metric}
      </td> 
  {getMonths().map((month) => (
    <React.Fragment key={month}>
      <td colSpan="3" style={{ padding: '8px', border: '2px solid #bbb',textAlign:"right",fontWeight:"bold" }}>
      {(() => {
          const amount = renderMetricData('Variance in Revenue', month).amount;
          return amount < 0 ? `(${Math.abs(amount)})` : amount || '0';
        })()}
      </td>
    </React.Fragment>
  ))}
</tr>
 ))}
        </tbody>
      </table>
      </div>
    
</div>
<div style={{marginTop:"-10px"}} >
     <button onClick={() => setIsModalOpen(true)} style={{ backgroundColor: "green", padding: '10px',color:"white",borderRadius:"10px"}}>Billing Input</button>
      </div>
{isModalOpen && (

<div style={{
      position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
      backgroundColor: 'white', padding: '20px', border: '1px solid #ccc', zIndex: 1, width: '60%'
    }}>
      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <label>Month:</label>
        <select value={selectedMonth} onChange={handleMonthChange}>
          <option value="">Select a month</option>
          {monthOptions.map((option) => (
            <option key={option.month} value={option.month}>
              {option.month}
            </option>
          ))}
        </select>
      </div>

      <table border="1" cellPadding="5" cellSpacing="0" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Item</th>
            <th>KWh</th>
            <th>Unit Price (S$)</th>
            <th>Amount (S$)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Solar Generation</td>
            <td>{formatNumberWithCommas(data["Solar Generation"].kWh)}</td>
            <td>{data["Solar Generation"]["Unit Price"]}</td>
            <td>{formatNumberWithCommas(data["Solar Generation"].Amount)}</td>
          </tr>
          <tr>
            <td>Exported Generation</td>
            <td>
              <input
                type="text"
                value={data["Exported Generation"].kWh}
                onChange={(e) => handleInputChange(e, "Exported Generation", "kWh")}
              />
            </td>
            <td>
              <input
                type="text"
                value={data["Exported Generation"]["Unit Price"]}
                onChange={(e) => handleInputChange(e, "Exported Generation", "Unit Price")}
              />
            </td>
            <td>{formatNumberWithCommas(data["Exported Generation"].Amount)}</td>
          </tr>
          <tr>
            <td>Consumed Generation</td>
            <td>{formatNumberWithCommas(data["Consumed Generation"].kWh)}</td>
            <td>{data["Consumed Generation"]["Unit Price"]}</td>
            <td>{formatNumberWithCommas(data["Consumed Generation"].Amount)}</td>
          </tr>
          <tr>
            <td>Administrative Fees</td>
            <td colSpan="2"></td>
            <td>
              <input
                type="text"
                value={data["Administrative Fees"].Amount}
                onChange={(e) => handleInputChange(e, "Administrative Fees", "Amount")}
              />
            </td>
          </tr>
          <tr>
            <td>Total Revenue</td>
            <td colSpan="2"></td>
            <td>{formatNumberWithCommas(data["Total Revenue"].Amount)}</td>
          </tr>
          
        </tbody>
      </table>
      <button onClick={sendDataToBackend} style={{backgroundColor:"yellow",display:"flex",alignItems:"center"}}>Save Data</button>
      <button onClick={() => setIsModalOpen(false)} style={{ backgroundColor: "red", padding: '8px',borderRadius:"10px" }}>Close</button>
  
    </div>
    )}
    </div>
    </div>
  );
};

export default RevenueTable;
