
import React, { useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faEye,faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/Login.css';
import { useNavigate } from 'react-router-dom';


function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://siva-solar-back.fcic.cc/login', {
        username,
        password
      });
  
      
      const { userId, userType } = response.data.user;
      
      onLogin(userId,userType);
      
      navigate('/newoverview');
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid username or password. Please try again.');
    }
  };

  const eyeIconStyle = {
    position: 'absolute',
    right: '10px',
    top: '50%',
    cursor: 'pointer',
    transform: 'translateY(-50%)',
    color: '#888',
    fontSize: '18px',
   
  };
  const inputStyle = {
    width: '88%',
    padding: '10px',
    paddingRight: '30px', 
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '5px'
  };
  return (
    <main className="d-flex w-100 login-main">
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="text-center mt-4">
                <h1 className="h2">Welcome</h1>
                <p className="lead">Sign in to your account</p>
              </div>

              <div className="card">
                <div className="text-center">
                  <img className="product-image" src="./img/TresLogo.jpg" alt="Logo" />
                </div>
                <div className="card-body">
                  <div className="m-sm-3">
                    {error && (
                      <div id="login-error-msg-holder">
                        <p id="login-error-msg">{error}</p>
                      </div>
                    )}

                    <form id="login-form1">
                      <div className="mb-3 input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                        </div>
                        <input className="form-control login-form-field" id="username-field" type="text"
                          value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                      </div>

                      <div className="mb-3 input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faLock} />
                          </span>
                        </div>
                        <input style={inputStyle} id="password-field"  type={showPassword ? 'text' : 'password'}
                          value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                    
                    <span style={eyeIconStyle} onClick={() => setShowPassword(!showPassword)}>
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
      </span>
                      </div>

                      <button type="button" className="btn btn-primary" id="login-form-submit" onClick={handleLogin}>Login</button>
                    </form>
                    
                  </div>
                  
                </div>
                
              </div>
          
              <h5><strong>Contact us: </strong> <a href="mailto:support@trek2000.com.sg">support@trek2000.com.sg</a></h5>

            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Login;
