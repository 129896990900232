import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SolarTable.css';

const PPATable = ({ year, yearlyValue, setYearlyValue, yearToDateValue, setYearToDateValue, selectedPlant }) => {
  const [monthlyValues, setMonthlyValues] = useState({});
  const [previousYearlyValue, setPreviousYearlyValue] = useState(0); 
  const [projectedValues, setProjectedValues] = useState({});
  const [projectYearToDate, setProjectYearToDate] = useState('');
  // const [previousYearlyValue, setPreviousYearlyValue] = useState(0);
  const [previousTwoYearsValue, setPreviousTwoYearsValue] = useState(0); 
  
// console.log("yearlyvaluesss:",yearlyValue);

  const calculateYearlyValue = (values) => {
    const total = Object.values(values).reduce((acc, value) => {
      const numericValue = parseFloat(String(value).replace(/,/g, '')) || 0;
      return acc + numericValue;
    }, 0);
    return total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  const calculateDeviation = (projected, actual) => {
    const parsedProjected = parseFloat(projected.replace(/,/g, ''));
    const parsedActual = parseFloat(actual.replace(/,/g, ''));
    if (!parsedActual || parsedActual === 0) return null;
    return ((parsedActual - parsedProjected) / parsedProjected) * 100;
   };
  
  const calculateYearlyDeviation = (projectedValues, yearlyValue) => {
    const totalProjected = Object.values(projectedValues).reduce((acc, val) => acc + (parseFloat(val) || 0), 0);
    const totalActual = parseFloat(yearlyValue.replace(/,/g, '')) || 0;
    const deviation = totalProjected
      ? ((totalActual - totalProjected) / totalProjected) * 100
      : null;
  
    // Return the deviation with a sign
    return deviation !== null ? deviation.toFixed(2) + '%' : '';
  };
  
  const calculateYearToDateDeviation = (projectYearToDate, yearToDateValue) => {
    const totalProjected = parseFloat(projectYearToDate.replace(/,/g, '')) || 0;
    const totalActual = parseFloat(yearToDateValue.replace(/,/g, '')) || 0;
    const deviation = totalProjected
      ? ((totalActual - totalProjected) / totalProjected) * 100
      : null;
  
   
    return deviation !== null ? deviation.toFixed(2) + '%' : '';
  };

  const getDeviationColor = (deviation) => {
    if (!deviation) return 'black'; 
    const numericValue = parseFloat(deviation);
    return numericValue < 0 ? 'red' : 'green'; 
  };
  

  useEffect(() => {
  
  const calculateProjectedValues = () => {
    if (selectedPlant === 'Sweelee') {
      if (year === '2023' || year === 2023) {
        return { Sep: 1809.84, Oct: 1809.84, Nov: 1809.84, Dec: 1809.84 };
      } else if (year === '2024' || year===2024) {
        return {
          Jan: 1798.99, Feb: 1798.99, Mar: 1798.99, Apr:1798.99,
          May: 1798.99, Jun: 1798.99, Jul: 1798.99, Aug:1798.99,
          Sep: 1798.99, Oct: 1798.99, Nov: 1798.99, Dec: 1798.99
        };
      } else if (year === '2025'|| year===2025) {
        return {
          Jan: 1788.19
        };
        }
        else {
          return {
            Jan: "", Feb: "", Mar: "", Apr: "",
            May: "", Jun: "", Jul: "", Aug: "",
            Sep: "", Oct: "", Nov: "", Dec: ""
          };
        }
    } else if (selectedPlant === "Bodyknits") {
    if (year === '2024' || year === 2024) {
      return {
        Jan: 1621.72, Feb: 1621.72, Mar: 1621.72, Apr: 1621.72,
        May: 1621.72, Jun: 1621.72, Jul: 1621.72, Aug: 1621.72,
        Sep: 1621.72, Oct: 1621.72, Nov: 1621.72, Dec: 1621.72
      };
    } else if (year === '2025' || year === 2025) {
      return {
        Jan: 1612.61,
      };
    } else {
      return {
        Jan: "", Feb: "", Mar: "", Apr: "",
        May: "", Jun: "", Jul: "", Aug: "",
        Sep: "", Oct: "", Nov: "", Dec: ""
      };
    }
  }else if (selectedPlant === "NicoSteel") {
    if (year === '2025' || year === 2025) {
      return {
        Jan: 2511.25,
      };
    } else {
      return {
        Jan: "", Feb: "", Mar: "", Apr: "",
        May: "", Jun: "", Jul: "", Aug: "",
        Sep: "", Oct: "", Nov: "", Dec: ""
      };
    }
  }
}
  const calculateProjectYearToDate = () => {
    if (selectedPlant === "Sweelee") {
    return year === '2023' || year === 2023
      ? '7,239.36'
      : year === '2024' || year === 2024
      ? '28,827.24'
      : year === '2025' || year === 2025
      ? '30,615.43'
      : '0';
  }else if (selectedPlant === "Bodyknits") {

    return year === '2024' || year === 2024
    ? '19,460.64'
    : year === '2025' || year === 2025
    ? '21,073.25'
    : '0';
  } else if (selectedPlant === "NicoSteel") {

    return  year === '2025' || year === 2025
    ? '2,511.25'
    : '0';
  }else {
    return '0'; 
  }
}
  setProjectedValues(calculateProjectedValues());
  setProjectYearToDate(calculateProjectYearToDate());

const fetchDataForYear = async () => {
  try {
    const response = await axios.post('/fmrequest', { plant: selectedPlant });

    if (response.data.length > 0) {
      const revenueData = response.data[0].Revenue || 0;
      const parsedData = typeof revenueData === 'string' ? JSON.parse(revenueData) : revenueData;

      if (!parsedData || typeof parsedData !== 'object') {
        console.error("Parsed data is not an object or is undefined");
        return;
      }

      // Fetch current year revenue
      const filteredCurrentYearValues = Object.fromEntries(
        Object.entries(parsedData)
          .filter(([key]) => key.endsWith(`-${year.toString().slice(-2)}`))
          .map(([key, value]) => [key.split('-')[0], value["Total Revenue"]?.Amount || 0])
      );

      const totalCurrentYear = calculateYearlyValue(filteredCurrentYearValues);
      setMonthlyValues(filteredCurrentYearValues);
      setYearlyValue(totalCurrentYear);

      // Fetch previous years' revenue
      let totalPreviousYear = 0;
      let totalTwoYearsAgo = 0;

      if (year >= 2024) {
        const filteredPreviousYearValues = Object.fromEntries(
          Object.entries(parsedData)
            .filter(([key]) => key.endsWith('-23')) // Fetch 2023 data
            .map(([key, value]) => [key.split('-')[0], value["Total Revenue"]?.Amount || 0])
        );
        totalTwoYearsAgo = calculateYearlyValue(filteredPreviousYearValues);
        setPreviousTwoYearsValue(parseFloat(totalTwoYearsAgo.replace(/,/g, '')));
      }

      if (year >= 2025) {
        const filteredTwoYearsAgoValues = Object.fromEntries(
          Object.entries(parsedData)
            .filter(([key]) => key.endsWith('-24')) // Fetch 2024 data
            .map(([key, value]) => [key.split('-')[0], value["Total Revenue"]?.Amount || 0])
        );
        totalPreviousYear = calculateYearlyValue(filteredTwoYearsAgoValues);
        setPreviousYearlyValue(parseFloat(totalPreviousYear.replace(/,/g, '')));
      }
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

fetchDataForYear();
setYearlyValue('');
setYearToDateValue('');
setProjectedValues(calculateProjectedValues());
setProjectYearToDate(calculateProjectYearToDate());
}, [year, selectedPlant,setYearlyValue,setYearToDateValue]);

useEffect(() => {
  if (year === 2024) {
    const total2024Numeric = parseFloat(yearlyValue.replace(/,/g, '')) || 0;
    // const previousYearNumeric = parseFloat(previousYearlyValue) || 0;
    const total2023Numeric = parseFloat(previousTwoYearsValue) || 0;
    // Corrected YTD calculation
    const ytdTotal = total2024Numeric + total2023Numeric;

    setYearToDateValue(
      ytdTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    );
  } else if (year === 2025) {
    const total2025Numeric = parseFloat(yearlyValue.replace(/,/g, '')) || 0;
    const total2024Numeric = parseFloat(previousYearlyValue) || 0;
    const total2023Numeric = parseFloat(previousTwoYearsValue) || 0;
    
    const ytdTotal = total2025Numeric + total2024Numeric + total2023Numeric;
    
    setYearToDateValue(
      ytdTotal.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    );
  } else {
    setYearToDateValue(yearlyValue);
  }
}, [year, yearlyValue, previousYearlyValue, previousTwoYearsValue, setYearToDateValue, setYearlyValue]);





  return (
    <div>
      <h5 style={{ fontWeight: 'bold' }}>PPA Revenue (S$) ({year})</h5>
      <p>
  {selectedPlant === 'Sweelee' 
    ? 'Swee Lee Project (Billing cycle from 10th of current month to 9th of following month)' 
    : selectedPlant === 'Bodyknits' 
    ? 'Bodyknits Project (Billing cycle from 15th of current month to 14th of following month)' 
    : selectedPlant === 'NicoSteel' 
    ? 'Nico Steel Project (Billing cycle from 25th of current month to 24th of following month)' 
    : ''}
</p>
      <table className="solar-table" style={{ tableLayout: 'fixed', width: '100%' }}>
        <thead>
          <tr>
            <th colSpan="4" className="glitterRow" style={{backgroundColor:"grey",color:"white"}}>Monthly</th>
            <th colSpan="3" className="glitterRow" style={{backgroundColor:"grey",color:"white"}}>Yearly ({year})</th>
            <th colSpan="3" className="glitterRow"  style={{backgroundColor:"grey",color:"white"}}>
              Year to Date ({year === '2023' || year === 2023 ? '1 Year' : year === '2024' || year === 2024 ? '2 Years': year === '2025' || year === 2025 ? '3 Years' : ''})</th>
          </tr>
          <tr>
            <th rowSpan={2}>Period</th>
            <th colSpan={2}>Value</th>
            <th rowSpan={2}>Deviation <br></br>(%)</th> 
            <th colSpan={2}>Value</th>
            <th rowSpan={2}>Deviation <br></br>(%)</th>
            <th colSpan={2}>Value</th>
            <th rowSpan={2}>Deviation<br></br>(%)</th>
          </tr>
          <tr>
            <th style={{width:'15%'}}>Projected</th>
            <th style={{width:'15%'}} className="glitterRows">Actual</th>
            <th style={{width:'15%'}}>Projected</th>
            <th style={{width:'15%'}} className="glitterRows">Actual</th>
            <th style={{width:'15%'}}>Projected</th>
            <th style={{width:'15%'}} className="glitterRows">Actual</th>
          </tr>
        </thead>
        <tbody>
          {['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map((month, index) => {
            
           
            const projected = projectedValues[month]
            ? parseFloat(projectedValues[month].toString().replace(/,/g, '')).toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') 
            : '';
        
            // const actual = parseFloat(monthlyValues[month]?.toString().replace(/,/g, '')) || '';
            const actual = monthlyValues[month]
            ? parseFloat(monthlyValues[month].toString().replace(/,/g, '')).toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') 
            : '';
            const deviation = calculateDeviation(projected, actual);
            const formattedDeviation = deviation !== null
          ? deviation < 0
            ? `(${Math.abs(deviation).toFixed(2)})`  
            : `${Math.abs(deviation).toFixed(2)}`   
          : '';
            const deviationStyle = deviation !== null
              ? { color: deviation < 0 ? 'red' : 'green', fontWeight: 'bold' }
              : {};
            return (
              <tr key={month}>
                <td>{month}</td>
                <td style={{ color: "blue", fontWeight: "bold" }}>{projected.toLocaleString()}</td>
                <td style={{ color: "green", fontWeight: "bold" }}>{actual.toLocaleString()}</td>
                <td style={deviationStyle}>
               
                {formattedDeviation}
                </td>
                {index === 0 && (
                  <>
                    <td rowSpan="12" style={{ color: "blue", fontWeight: "bold" }}>
                       {Number(Object.values(projectedValues).reduce((acc, val) => acc + (parseFloat(val) || 0), 0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </td>
                    <td rowSpan="12" style={{ color: "green", fontWeight: "bold" }}>
                      {yearlyValue}
                    </td>
                    
                  <td rowSpan="12" style={{fontWeight: "bold",color: getDeviationColor(parseFloat(calculateYearlyDeviation(projectedValues, yearlyValue))), }}>
              {(() => {
                          const deviation = parseFloat(calculateYearlyDeviation(projectedValues, yearlyValue));
                          if (deviation !== null) {
      
                                const formattedDeviation = deviation < 0
                                    ? `(${Math.abs(deviation).toFixed(2)})`  
                                     : `${Math.abs(deviation).toFixed(2)}`;  
    
                               return formattedDeviation;
                               }
                              return '';  
               })()}
           </td>

                    <td rowSpan="12" style={{ color: "blue", fontWeight: "bold" }}>{projectYearToDate}</td>
                    <td rowSpan="12" style={{ color: "green", fontWeight: "bold" }}>
                      {yearToDateValue}
                    </td>
                    
<td rowSpan="12"
  style={{
    fontWeight: "bold",
    color: getDeviationColor(
      parseFloat(calculateYearToDateDeviation(projectYearToDate, yearToDateValue))
    ),
  }}
>
  {(() => {
    const deviation = parseFloat(calculateYearToDateDeviation(projectYearToDate, yearToDateValue));
    if (deviation !== null) {
      
      const formattedDeviation = deviation < 0
        ? `(${Math.abs(deviation).toFixed(2)})`  
        : `${Math.abs(deviation).toFixed(2)}`;  
      
      
      return formattedDeviation;
    }
    return '';  
  })()}
</td>
   
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default PPATable;

