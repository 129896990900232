import React, { useState, useEffect } from 'react';

const ProfitLoss = ({ yearlyValue,year,selectedPlant}) => {
  
  const [recsValue, setRecsValue] = useState('0');
  const [othersValue, setOthersValue] = useState('0');
  const [totalRevenue, setTotalRevenue] = useState('0');
  const [totalExpenditure, setTotalExpenditure] = useState('0');
  // const expenditures = {
  //   2023: {
  //     operationCost: 0,
  //     liabilityInsurance: 333.32,
  //     riskInsurance: 83.32,
  //     depreciation:  6087.12,
  //   },
  //   2024: {
  //     operationCost: 0,
  //     liabilityInsurance: 666.64,
  //     riskInsurance: 166.64,
  //     depreciation:15217.80,
  //   },
  // };
  // const currentExpenditures = expenditures[year] || expenditures[2023]; 

  const expenditures = {
    Sweelee: {
      2023: {
        operationCost: 0,
        liabilityInsurance: 333.32,
        riskInsurance: 83.32,
        depreciation: 6087.12,
      },
      2024: {
        operationCost: 0,
        liabilityInsurance: 666.64,
        riskInsurance: 166.64,
        depreciation:  18261.36,
      },
      2025: {
        operationCost: 0,
        liabilityInsurance: 0,
        riskInsurance: 0,
        depreciation:  1521.78,
      },
    },
    Bodyknits: {
      2023: {
        operationCost: 0,
        liabilityInsurance: 0,
        riskInsurance: 0,
        depreciation: 0,
      },
      2024: {
        operationCost: 0,
        liabilityInsurance: 0,
        riskInsurance: 795.04,
        depreciation: 13822.08,
      },
      2025: {
        operationCost: 0,
        liabilityInsurance: 0,
        riskInsurance: 0,
        depreciation: 1151.84,
      },
    },
    NicoSteel: {
      2023: {
        operationCost: 0,
        liabilityInsurance: 0,
        riskInsurance: 0,
        depreciation: 0,
      },
      2024: {
        operationCost: 0,
        liabilityInsurance: 0,
        riskInsurance: 0,
        depreciation: 0,
      },
      2025: {
        operationCost: 0,
        liabilityInsurance: 0,
        riskInsurance: 0,
        depreciation: 1081.94,
      },
    },
  };

  // Set expenditures based on the selected plant and year
  const currentExpenditures = expenditures[selectedPlant]?.[year] || expenditures.Sweelee[2023];




  useEffect(() => {
    // Calculate total expenditures based on the selected year's values
    const totalExp = (
      currentExpenditures.operationCost +
      currentExpenditures.liabilityInsurance +
      currentExpenditures.riskInsurance +
      currentExpenditures.depreciation
    ).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    
    setTotalExpenditure(totalExp);
  }, [
    selectedPlant,
    currentExpenditures.operationCost,
    currentExpenditures.liabilityInsurance,
    currentExpenditures.riskInsurance,
    currentExpenditures.depreciation
  ]);
  

  
  useEffect(() => {
    const calculateTotalRevenue = () => {
      const ppaNum = parseFloat(yearlyValue.replace(/,/g, '')) || 0;
      const recsNum = parseFloat(recsValue.replace(/,/g, '')) || 0;
      const othersNum = parseFloat(othersValue.replace(/,/g, '')) || 0;

      const total = ppaNum + recsNum + othersNum;
      return total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    setTotalRevenue(calculateTotalRevenue());
  }, [yearlyValue, recsValue, othersValue]); 

  return (
    <div>
      <table className="financial-table">
        <thead>
          <tr>
            <th>Profit and Loss</th>
            <th>Values (S$)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="section-title">Revenue</td>
            <td></td>
          </tr>
          <tr>
            <td>Revenue from Customers' contracts
            </td>
            <td>
              <input
                type="text"
                value={yearlyValue}
                
              />
            </td>
          </tr>
          <tr>
            <td>RECs</td>
            <td>
              <input
                type="text"
                value={recsValue}
                onChange={(e) => setRecsValue(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Others</td>
            <td>
              <input
                type="text"
                value={othersValue}
                onChange={(e) => setOthersValue(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>Total Revenue</td>
            <td>
              <input type="text" value={totalRevenue} readOnly />
            </td>
          </tr>
          <tr>
            <td className="section-title">Expenditures</td>
            <td></td>
          </tr>
          <tr>
            <td>Yearly Operation and Maintenance Cost (S$/Year)</td>
            <td><input type="text" value={currentExpenditures.operationCost.toLocaleString()}/></td>
          </tr>
          {/* <tr>
            <td className="indented-item">Public Liability Insurance Premium (S$/Year)</td>
            <td><input type="text" value={currentExpenditures.liabilityInsurance.toLocaleString()} /></td>
          </tr>
          <tr>
            <td className="indented-item">All Risk Liability Insurance Premium (S$/Year)</td>
            <td><input type="text" value={currentExpenditures.riskInsurance.toLocaleString()} /></td>
          </tr> */}
          {selectedPlant === 'Sweelee' ? (
  <>
    <tr>
      <td className="indented-item">Public Liability Insurance Premium (S$/Year)</td>
      <td>
        <input
          type="text"
          value={currentExpenditures.liabilityInsurance.toLocaleString()}
          readOnly
        />
      </td>
    </tr>
    <tr>
      <td className="indented-item">All Risk Liability Insurance Premium (S$/Year)</td>
      <td>
        <input
          type="text"
          value={currentExpenditures.riskInsurance.toLocaleString()}
          readOnly
        />
      </td>
    </tr>
  </>
) : (
  <tr>
    <td className="indented-item">Yearly Operation Insurance Cost (S$/Year)</td>
    <td>
      <input
        type="text"
        value={currentExpenditures.riskInsurance.toLocaleString()}
        readOnly
      />
    </td>
  </tr>
)}

          <tr>
            <td>Yearly Depreciation (S$)</td>
            <td><input type="text"  value={currentExpenditures.depreciation.toLocaleString()} /></td>
          </tr>
          <tr>
            <td>Total Expenditure (S$)</td>
            <td><input type="text" value={totalExpenditure} /></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProfitLoss;
