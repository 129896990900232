import React, { useState } from 'react';
import ProjectSummary from './Page1/ProjectSummary';
import Dashboard from './Page2/Dashboard';

import ExportPage from './ExportPage/exportPage';
// import Sample from './ExportPage/sample';
import './NewFMFinancial.css';

const FinancialModule = ({selectedPlant}) => {
  const [activeComponent, setActiveComponent] = useState('ProjectSummary');
  const [yearlyValue, setYearlyValue] = useState(''); 
  const [yearToDateValue, setYearToDateValue] = useState('');
 
  const [selectedYear, setSelectedYear] = useState("2025");

  console.log("selectedPlant:",selectedPlant);
  
  // console.log("year",selectedYear);
  // console.log("yearlyvalue",yearlyValue);

  const handleYearChange = (event) => {
    const year = Number(event.target.value); 
    setSelectedYear(year);
  };
  
  const renderComponent = () => {
    switch (activeComponent) {
      case 'ProjectSummary':
        return <ProjectSummary selectedPlant={selectedPlant} yearlyValue={yearlyValue} yearToDateValue={yearToDateValue} selectedYear={selectedYear} 
        handleYearChange={handleYearChange}   />;
      case 'DashboardDisplay':
        return (
          <Dashboard 
          selectedPlant={selectedPlant}
            yearlyValue={yearlyValue} 
            setYearlyValue={setYearlyValue} 
            yearToDateValue={yearToDateValue}
            setYearToDateValue={setYearToDateValue}
            selectedYear={selectedYear} 
            handleYearChange={handleYearChange} 
          /> 
        );
        case 'exportPage':
        return (
          <ExportPage selectedPlant={selectedPlant}
          /> 
        );
      
        
      default:
        return <ProjectSummary  selectedPlant={selectedPlant} yearlyValue={yearlyValue} yearToDateValue={yearToDateValue}
         selectedYear={selectedYear} handleYearChange={handleYearChange} />;
    }
  };

  return (
    <div className="fm-pages">
      <div className="content-finance">
        {renderComponent()}
      </div>
      <div className="fm-page">
        <button onClick={() => setActiveComponent('ProjectSummary')}>Page 1</button>
        <button onClick={() => setActiveComponent('DashboardDisplay')}>Page 2</button>
        <button onClick={() => setActiveComponent('exportPage')}>Page 3</button>
        {/* <button onClick={() => setActiveComponent('SamplePage')}>Page 4</button> */}

      </div>
    </div>
  );
};

export default FinancialModule;
