
import React, { useState, useEffect } from "react";
import axios from "axios";

const plants = [
  "32tuas",
  "36tuas",
  "40tuas",
  "80tuas",
  "15Tech",
  "73tuas",
  "110 Tuas - (A + B) ",
];

const SummarySG = () => {
  const [data, setData] = useState([]);
  const [totalYield, setTotalYield] = useState({
    daily: 0,
    monthly: 0,
    yearly: 0,
    yearToDate: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      let fetchedData = [];//used for daily,monthly,yearly and year to date 
      let total = { daily: 0, monthly: 0, yearly: 0, yearToDate: 0 };

      const today = new Date();
      // const currentYear = today.getFullYear();
      // const currentMonth = (today.getMonth() + 1).toString().padStart(2, "0");
      // const firstDayOfMonth = `${currentYear}-${currentMonth}-01`; // Used for monthly data
      const currentDateFormatted = today.toISOString().split("T")[0]; // Used for daily data

      for (const plant of plants) {
        try {
          const response = await axios.post("/v1/overview/station-real-kpi/", {
            plant: plant,
            timeZone: currentDateFormatted, // Pass today's date for daily
          });
          let totalYield = 0;

          if (response.data.success && response.data.data) {
            totalYield = response.data.data.cumulativeEnergy || 0;
            console.log("Totalyield:",totalYield);
            // Special handling for "32tuas"
            if (plant === "32tuas"|| plant === "36tuas" || plant === "40tuas"|| 
               plant === "73tuas"||plant === "80tuas"||plant === "15Tech") {
              try {
                const powerResponse = await axios.post('/api/lifetimetotalpower', {
                  plant: plant, // Use 'plant' variable here
                });
                console.log("cumulative yield", totalYield);
                const powerMetre = powerResponse.data.LifetimeTotalPower || 0; 
                const powerInMWh = powerMetre / 1000; // Convert to MWh
                console.log("powerInMWh", powerInMWh);
                const powerYield = (powerInMWh - totalYield);
                console.log("Power yields:", powerYield);
                totalYield = Number(powerYield) + Number(totalYield);  
                console.log("total yield:", totalYield);
              } catch (powerError) {
                console.error("Error fetching LifetimeTotalPower:", powerError);
              }
            }

            if (response.data.success) {
              const daily = Number(response.data.data.dailyEnergy) || 0;
              const monthly = response.data.data.hasOwnProperty("monthEnergy") ? Number(response.data.data.monthEnergy) : 0;
              const yearly = response.data.data.hasOwnProperty("yearEnergy") ? Number(response.data.data.yearEnergy) : 0;
              const yearToDate = totalYield || 0;

              fetchedData.push({
                plant,
                daily,
                monthly,
                yearly,
                yearToDate,
              });

              // // Accumulate totals
              total.daily += Number(daily) || 0;
              total.monthly += Number(monthly) || 0;
              total.yearly += Number(yearly) || 0;
              total.yearToDate += Number(yearToDate) || 0;

            }
          }
        } catch (error) {
          console.error("Error fetching data for plant:", plant, error);
          fetchedData.push({ plant, daily: 0, monthly: 0, yearly: 0, yearToDate: 0 });
        }
      }

      setData(fetchedData);
      setTotalYield(total);
    };

    fetchData();
  }, []);

  const getHeaderStyle = () => ({
    backgroundColor: 'grey',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
  });

  return (
<div
  style={{
    marginTop: '40px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow around the div
    backgroundColor: '#f9f9f9', // Light gray background color for the container
    overflowX: 'auto', // Ensures the table is scrollable horizontally if needed
  }}
>
  <table
    border="1"
    style={{
      width: '100%',
      borderCollapse: 'collapse',
      textAlign: 'center',
      fontFamily: "'Arial', sans-serif",
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Adds a shadow effect around the table
    }}
  >
    <thead>
      <tr>
        <th style={getHeaderStyle()}>No</th>
        <th style={getHeaderStyle()}>Plant Name</th>
        <th style={getHeaderStyle()}>Daily Yield (kWh)</th>
        <th style={getHeaderStyle()}>Monthly Yield (MWh)</th>
        <th style={getHeaderStyle()}>Yearly Yield (MWh)</th>
        <th style={getHeaderStyle()}>Year to Date Yield (MWh)</th>
      </tr>
    </thead>
    <tbody>
      {data.map((item, index) => (
        <tr
          key={index}
          style={{
            backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white', // Alternating row colors
            cursor: 'pointer',
          }}
        >
          <td>{index + 1}</td>
          <td 
          style={{backgroundColor:"lightblue", fontWeight:"bold"}}>
            {/* {item.plant === "32tuas" ? "32 Tuas" : item.plant}</td> */}
            {item.plant === "32tuas" ? "32 Tuas" : item.plant === "40tuas" ? "40 Tuas":
             item.plant === "36tuas" ? "36 Tuas" :item.plant === "73tuas" ? "73 Tuas": item.plant}
            </td>
          <td>{item.daily.toFixed(2)}</td>
          <td>{item.monthly.toFixed(2)}</td>
          <td>{item.yearly.toFixed(2)}</td>
          {/* <td>{item.yearToDate.toFixed(3)}</td> */}
          <td>{Number(item.yearToDate || 0).toFixed(3)}</td>

        </tr>
      ))}
      <tr
        style={{
          backgroundColor: 'yellow',
          fontWeight: 'bold',
          fontSize: '16px',
          color: 'black',
        }}
      >
        <td colSpan="2">Total</td>
        <td>{totalYield.daily.toFixed(2)}</td>
        <td>{totalYield.monthly.toFixed(2)}</td>
        <td>{totalYield.yearly.toFixed(2)}</td>
        <td>{Number(totalYield.yearToDate || 0).toFixed(3)}</td>

        {/* <td>{totalYield.yearToDate.toFixed(3)}</td> */}
      </tr>
    </tbody>
  </table>
</div>

    );
};

export default SummarySG;
