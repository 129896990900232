import React from 'react';
import { Tooltip } from 'react-tooltip';

import { FaFileAlt, FaCalculator} from "react-icons/fa";

const SystemCosts = ({selectedPlant,showProjectProfile,toggleProjectProfile, projectInfo, handleInputChange, calculateCosts }) => {
 
  const plantData = {
    Sweelee: {
      commencementDate: "2023-08-14",
      powerGeneration: 135,
      roofType: "Metal",
      projectDuration: 25,
      ppaPrice: 0.13,
    },
    Bodyknits: {
      commencementDate: "2023-12-22",
      powerGeneration: 147,
      roofType: "Metal",
      projectDuration: 20,
      ppaPrice: 0.12,
    },
    NicoSteel: {
      commencementDate: "2024-12-02",
      powerGeneration: 116,
      roofType: "Metal",
      projectDuration: 17,
      ppaPrice: 0.13,
    },
  };
  const systemCostsData = {
    Sweelee: {
      solarPanels: 56133,
      inverters: "-",
      smartLoggers: "-",
      others: 2000,
      epcCosts: 124480,
      totalSystemCosts: 182613,
    },
    Bodyknits: {
      solarPanels: 40668,
      inverters: 9654,
      smartLoggers: "-",
      others: "-",
      epcCosts: 87898,
      totalSystemCosts: 138220,
    },
    NicoSteel: {
      solarPanels: 24226,
      inverters: 6970,
      smartLoggers:233,
      others: "-",
      epcCosts:94000,
      totalSystemCosts: 125429,
    },
  };

  const iconStyle = { marginLeft: "8px", color: "blue" };
  
  return (
    <div className="right-column">
      {showProjectProfile ? (
        <button
        style={{
          fontSize: '16px',
          color: 'white',
          backgroundColor: 'green',  
          border: 'none',
          padding: '5px 15px',
          cursor: 'pointer',
          borderRadius: '5px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={toggleProjectProfile}
      >
        Close
      </button>
      

      ) : (
        <>
         
<button
  data-tooltip-id="plus-tooltip"
  data-tooltip-content="Click to view the table"
  style={{
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'green',
    border: 'none',
    padding: '5px 15px',
    cursor: 'pointer',
    float: 'right',
    marginRight: '18px',
    borderRadius: '5px',
    alignItems: 'center',
          justifyContent: 'center',
  }}
  onClick={toggleProjectProfile}
>
  View 
</button>
      <span
  style={{
    fontSize: '20px',
    cursor: 'pointer',
    float: "right",
    marginRight: '10px',
    alignItems: 'center',
          justifyContent: 'center',
  }}
  onClick={toggleProjectProfile}
>
  Project Profile
</span>


<Tooltip id="plus-tooltip" place="top" effect="solid" />

        </>
      )}

      {showProjectProfile && (
        <div>
        <div>
            <h4>Project Profile <FaFileAlt style={iconStyle} /></h4>
        <table>
        <tbody>
          <tr>
            <th>Project Information</th>
            <th>Values</th>
          </tr>
          <tr>
            <td>Commencement Date</td>
            <td><input type="text" value= {plantData[selectedPlant]?.commencementDate} style={{fontSize:"13px"}} readOnly/></td>
          </tr>
          <tr>
            <td>Power Generation (kWp) (Day)</td>
            <td><input type="text" value={plantData[selectedPlant]?.powerGeneration} readOnly/></td>
          </tr>
          <tr>
            <td>Roof Type</td>
            <td><input type="text" value={plantData[selectedPlant]?.roofType} readOnly /></td>
          </tr>
          <tr>
            <td> Project Duration (Years)</td>
            <td><input type="text" value={plantData[selectedPlant]?.projectDuration} readOnly /></td>
          </tr>
          <tr>
            <td>PPA Price (S$/kWh)</td>
           <td><input type="text" value={plantData[selectedPlant]?.ppaPrice} readOnly /></td>
          
          </tr>
        </tbody>
      </table>
      </div>
        <div className='systemCost'>
          <h4>System Costs <FaCalculator style={iconStyle} /></h4>
          <table>
            <tbody>
              <tr>
                <th>System Costs</th>
                <th>Values</th>
              </tr>
              <tr>
                <td>Solar Panels (S$)</td>
                <td><input type="text" value={systemCostsData[selectedPlant]?.solarPanels 
      ? systemCostsData[selectedPlant].solarPanels.toLocaleString() 
      : "0"} readOnly/></td>
               
              </tr>
              <tr>
                <td>Inverters (S$)</td>
                {/* <td><input type="text" value="-" readOnly /></td> */}
                <td><input type="text" value={systemCostsData[selectedPlant]?.inverters 
      ? systemCostsData[selectedPlant].inverters.toLocaleString() 
      : "0"} readOnly/></td>
           
              </tr>
              <tr>
                <td>Smart Loggers & Dashboards (S$)</td>
                <td><input type="text" value={systemCostsData[selectedPlant]?.inverters 
      ? systemCostsData[selectedPlant].smartLoggers.toLocaleString() 
      : "0"} readOnly/></td>
                </tr>
              <tr>
                
                <td>Others (S$)</td>
                <td><input type="text" value={systemCostsData[selectedPlant]?.others 
      ? systemCostsData[selectedPlant].others.toLocaleString() 
      : "0"} readOnly  /></td>
               </tr>
              <tr>
                <td>EPC Costs (S$)</td>
                <td><input type="text" value={systemCostsData[selectedPlant]?.epcCosts 
      ? systemCostsData[selectedPlant].epcCosts.toLocaleString() 
      : "0"} readOnly/></td>
            
              </tr>
              <tr>
                <td>Total System Costs (S$)</td>
                <td><input type="text" value={systemCostsData[selectedPlant]?.totalSystemCosts 
      ? systemCostsData[selectedPlant].totalSystemCosts.toLocaleString() 
      : "0"} readOnly /></td>
              
              </tr>
            </tbody>
          </table>
        </div>
     
        </div>
      )}
    </div>
   
  );
};

export default SystemCosts;
